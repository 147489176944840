import { queryGraphql } from '../../axios/axios'

const actEcovalencias = {
  LISTA_ECOVALENCIAS: 'LISTA_ECOVALENCIAS',
  LOADING_ECOVALENCIAS: 'LOADING_ECOVALENCIAS',
  CALC_ECOVALENCIAS: 'CALC_ECOVALENCIAS',
  ERROR_ECOVALENCIAS: 'ERROR_ECOVALENCIAS',
  LIMPIAR_SUMA: 'LIMPIAR_SUMA'
}

const ecovalenciasLista = () => {
  return async (dispatch) => {
    try{
      dispatch({ type: actEcovalencias.LOADING_ECOVALENCIAS, payload: true })
        const { data: payload } = await queryGraphql(
            {
            query: `query {
                ecovalencias{
                un_de
                equivale_a
                de
              }
            }`
          }
        )
        console.debug(payload)
        payload.ecovalencias.map(eco => eco.suma_tmp=0)
        dispatch({ type: actEcovalencias.LISTA_ECOVALENCIAS, payload: payload.ecovalencias })
    }catch(e){
      dispatch({ type: actEcovalencias.ERROR_ECOVALENCIAS })
      console.log(e);
    }
  }
}

const calculaEcovalencias = (edificio, periodoFin) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actEcovalencias.LOADING_ECOVALENCIAS, payload: true })
      const { data: payload } = await queryGraphql(
          {
          // consulta para suma total por categoría(para ecovalencias) hasta el periodo seleccionado y lista de categorías
          // , $fechaIni: String
          query: `query qry($edificio: Int, $fechaFin: String){
            retirosConnection(where:{ edificio:$edificio, fecha_retiro_lte: $fechaFin }) {
              groupBy {
                categoria {
                  connection {
                    aggregate {
                      sum {
                        kilogramos_retirados
                      }
                    }
                  }
                  key          
                }
              }
            }
            categorias{
              id
              nombre
            }
          }`,
          variables: {
            "edificio": edificio,
            // "fechaIni": periodoInicio,
            "fechaFin": periodoFin
          }
        }
      )
      
      const datos = payload.retirosConnection.groupBy.categoria
      const categorias = payload.categorias
      console.debug(datos)
      console.debug(categorias)
      categorias.map(cat => (cat.kilogramos = (datos.filter(suma => cat.id === suma.key)[0])?datos.filter(suma => cat.id === suma.key)[0].connection.aggregate.sum.kilogramos_retirados:null) )

      dispatch({ type: actEcovalencias.LIMPIAR_SUMA })
      dispatch({ type: actEcovalencias.CALC_ECOVALENCIAS, payload: categorias })
      dispatch({ type: actEcovalencias.LOADING_ECOVALENCIAS, payload: false })
      
    }catch(e){
      dispatch({ type: actEcovalencias.ERROR_ECOVALENCIAS })
      console.log(e);
    }
  }
}

export { ecovalenciasLista, calculaEcovalencias, actEcovalencias }