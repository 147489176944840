import { queryGraphql } from '../../axios/axios'
import { ecovalenciasLista } from './ecovalencias'
import { fechaAtexto, textoAFecha, InicioMesFecha, FinMesFecha, fechaActual } from '../../utils/momentUtils'

export const actApp = {
  SET_PERIODO: 'SET_PERIODO',
  SET_PERIODOS: 'SET_PERIODOS',
  SET_PERIODO_INICIO: 'SET_PERIODO_INICIO',
  SET_PERIODO_FIN: 'SET_PERIODO_FIN',
  SET_EDIFICIO: 'SET_EDIFICIO',
  SET_CONSEJOS: 'SET_CONSEJOS',
  SET_CONTENIDOS: 'SET_CONTENIDOS',
  SET_CERTIFICADOS_EMPRESA: 'SET_CERTIFICADOS_EMPRESA',
  SET_BIBLIOGRAFIAS: 'SET_BIBLIOGRAFIAS',
  SET_ULTIMO_RETIRO: 'SET_ULTIMO_RETIRO',
  LOADING_APP_CONF: 'LOADING_APP_CONF',
  LOADING_CONSEJOS: 'LOADING_CONSEJOS',
  LOADING_BIBLIOGRAFIAS: 'LOADING_BIBLIOGRAFIAS'
}

export const setearPeriodo = (periodo) => {
  return async (dispatch) => {
    dispatch({ type: actApp.SET_PERIODO, payload: {periodo: periodo, periodoTexto: fechaAtexto(textoAFecha(periodo, "MMYYYY"), "MMMM YYYY" ) } })
  }
}

export const setearPeriodos = (periodoInicio, periodoFin) => {
  return async (dispatch) => {
    const periodos = { inicio: periodoInicio, fin: periodoFin}
    try{
      dispatch({ type: actApp.LOADING_APP_CONF, payload: true})
      dispatch({ type: actApp.SET_PERIODOS, payload: periodos })
      dispatch({ type: actApp.LOADING_APP_CONF, payload: false})
    }catch(e){
      console.error(e);
    }
  }
}

export const setearPeriodoInicio = (periodoInicio) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actApp.LOADING_APP_CONF, payload: true})
      dispatch({ type: actApp.SET_PERIODO_INICIO, payload: periodoInicio })
      dispatch({ type: actApp.LOADING_APP_CONF, payload: false})
    }catch(e){
      console.log(e);
    }
  }
}

export const setearPeriodoFin = (periodoFin) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actApp.LOADING_APP_CONF, payload: true})
      dispatch({ type: actApp.SET_PERIODO_FIN, payload: periodoFin })
      dispatch({ type: actApp.LOADING_APP_CONF, payload: false})
    }catch(e){
      console.log(e);
    }
  }
}
export const setearEdificio = (edificio) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actApp.SET_EDIFICIO, payload: edificio })
    }catch(e){
      console.log(e);
    }
  }
}
export const setearConsejos = () => {
  return async (dispatch) => {
    try{
      dispatch({ type: actApp.LOADING_CONSEJOS, payload: true})
        const { data: payload } = await queryGraphql(
            {
            query: `query{
                consejos{
                  id
                  consejo    
                }
              }`
          }
        )
        // console.debug(payload)
        dispatch({ type: actApp.SET_CONSEJOS, payload: payload.consejos })
        dispatch({ type: actApp.LOADING_CONSEJOS, payload: false})
    }catch(e){
      console.log(e);
    }
  }
}

export const setearBibliogafias = () => {
  return async (dispatch) => {
    try{
      dispatch({ type: actApp.LOADING_BIBLIOGRAFIAS, payload: true})
        const { data: payload } = await queryGraphql(
            {
            query: `query{
                bibliografias{
                  id
                  nombre
                  documento{
                    name
                    url
                  }
              }
              }`
          }
        )
        dispatch({ type: actApp.SET_BIBLIOGRAFIAS, payload: payload.bibliografias })
    }catch(e){
      console.log(e);
    }
  }
}

export const setearCertificados = (empresa) => {
  return async (dispatch) => {
    try{
      // dispatch({ type: actApp.LOADING_BIBLIOGRAFIAS, payload: true})
        const { data: payload } = await queryGraphql(
          {
            query: `query qry($id: ID!){
              empresa(id: $id){
                tipo_certificados{
                  id
                  nombre
                }
              }
            }`,
            variables: {"id": empresa}
          }
        )
        dispatch({ type: actApp.SET_CERTIFICADOS_EMPRESA, payload: payload.empresa.tipo_certificados })
    }catch(e){
      console.log(e);
    }
  }
}

export const setearContenidos = () => {
  return async (dispatch) => {
    try{
      // dispatch({ type: actApp.LOADING_BIBLIOGRAFIAS, payload: true})
        const { data: payload } = await queryGraphql(
          {
            query: `query contenidos{
              contenidos{
                id
                titulo
                icono
                color_texto
                color_fondo
                contenido
                link
              }
            }`
          }
        )
        dispatch({ type: actApp.SET_CONTENIDOS, payload: payload.contenidos })
    }catch(e){
      console.log(e);
    }
  }
}

export const setearBase = (empresa) => {
  return async (dispatch) => {
    try{
      dispatch(setearCertificados(empresa))
      dispatch(ecovalenciasLista())
      dispatch(setearConsejos())
      dispatch(setearBibliogafias())
      dispatch(setearContenidos())
    }catch(e){
      console.log(e);
    }
  }
}
// export { setearPeriodos, setearPeriodoInicio, setearPeriodoFin, setearEdificio, actApp }