import { actUseAuth } from '../actions/useAuth'

const DEFAULT_STATE = {
  jwt: null,
  user: {},
  menu: [],
  loggedIn: false,
  loading: false,
  error: null
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actUseAuth.LOADING:
      return { ...state, loading: true, error: null, menu: null }
    case actUseAuth.ERROR:
      return { ...state, error: action.payload, loading: false }
      case actUseAuth.UNSET_ERROR:
        return { ...state, error: null, loading: false }
    case actUseAuth.LOGIN:
      const { jwt, user } = action.payload
      return { ...state, jwt, user, loggedIn: true, loading: false }
    case actUseAuth.FORGOT:
      return { ...state, jwt, loading: false }
    case actUseAuth.RESET:
      return { ...state, jwt, loading: false }
    case actUseAuth.LOGOUT:
      console.log("el LOGOUT")
      return DEFAULT_STATE
    case actUseAuth.MENU: 
        const { menuRol } = action.payload
        return { ...state, menu: menuRol.menus }
    default:
      return state
  }
}


  // const isAuthenticated = state.loggedIn && Object.keys(state.user).length
  
