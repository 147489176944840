import { actApp } from '../actions/app'

const initialState = {
    categorias: [{
        "id": "1",
        "nombre": "Vidrio",
        "kilogramos": null
     }, {
        "id": "2",
        "nombre": "Cartón",
        "kilogramos": null
     }, {
        "id": "3",
        "nombre": "Papel",
        "kilogramos": null
     }, {
        "id": "4",
        "nombre": "PET",
        "kilogramos": null
     }, {
        "id": "5",
        "nombre": "Latas",
        "kilogramos": null
     }, {
        "id": "6",
        "nombre": "EPS(Plumavit)",
        "kilogramos": null
     }, {
        "id": "7",
        "nombre": "Orgánicos",
        "kilogramos": null
     }
    ],
    edificio: null,
    consejos: null,
    bibliografias: null,
    certificados: null,
    contenidos: [],
    periodo: {},
    periodoInicio: null,
    periodoFin: null,
    ultimoRetiro: null,
    loadingConsejos: false,
    loadingBibliografias: false,
    loading: false
};

export default (state = initialState, action) => {
switch (action.type) {
    case actApp.SET_PERIODOS:
        return { ...state, periodoInicio: action.payload.inicio, periodoFin: action.payload.fin };
    case actApp.SET_PERIODO:
        return { ...state, periodo: action.payload };
    case actApp.SET_PERIODO_INICIO:
        return { ...state, periodoInicio: action.payload };
    case actApp.SET_PERIODO_FIN:
        return { ...state, periodoFin: action.payload };
    case actApp.SET_EDIFICIO:
        return { ...state, edificio: action.payload };
    case actApp.SET_CONSEJOS:
        return { ...state, consejos: action.payload };
    case actApp.SET_CONTENIDOS:
        return { ...state, contenidos: action.payload };
    case actApp.SET_BIBLIOGRAFIAS:
        return { ...state, bibliografias: action.payload, loadingBibliografias:false };
    case actApp.SET_CERTIFICADOS_EMPRESA:
        return { ...state, certificados: action.payload};
    case actApp.SET_ULTIMO_RETIRO:
        state.edificio.ultimo_retiro=action.payload
        return { ...state, ultimoRetiro: action.payload};
    case actApp.LOADING_APP_CONF:
        return  {...state, loading: action.payload};
    case actApp.LOADING_CONSEJOS:
        return  {...state, loadingConsejos: action.payload};
    case actApp.LOADING_BIBLIOGRAFIAS:
        return  {...state, loadingBibliografias: action.payload};
    case 'LOGOUT':
        return initialState
    default:
        return state;
    }
};