import { queryGraphql } from '../../axios/axios'

const actComentarios = {
  LISTA_COMENTARIOS: 'LISTA_COMENTARIOS',
  LOADING_COMENTARIOS: 'LOADING_COMENTARIOS',
  ERROR_COMENTARIOS: 'ERROR_COMENTARIOS'
}

const comentariosPeriodoEdificio = (edificio, periodoInicio, periodoFin) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actComentarios.LOADING_COMENTARIOS, payload: true })
        const { data: payload } = await queryGraphql(
            {
            query: `query qry($edificio: Int, $fechaIni: String, $fechaFin: String){
              comentarios(where:{edificio:$edificio, periodo_gte: $fechaIni, periodo_lte: $fechaFin }){
                id
                comentario
                periodo
              }
            }`,
            variables: {
              "edificio": edificio,
              "fechaIni": periodoInicio,
              "fechaFin": periodoFin
            }
          }
        )
        console.debug(payload)
        dispatch({ type: actComentarios.LISTA_COMENTARIOS, payload: payload.comentarios })
    }catch(e){
      dispatch({ type: actComentarios.ERROR_COMENTARIOS })
      console.log(e);
    }
  }
}

export { comentariosPeriodoEdificio, actComentarios }