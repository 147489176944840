import { actCertificados } from '../actions/certificados'

const DEFAULT_STATE = {
  lista: null,
  loading: false,
  error: null,
  fechas_informes: null
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actCertificados.LOADING_CERTIFICADOS:
        return { ...state, loading: action.payload, error: null }
    case actCertificados.ERROR_CERTIFICADOS:
        return { ...state, error: true, loading: false }
    case actCertificados.LISTA_CERTIFICADOS:
        return { ...state, lista: action.payload, loading: false }
    case actCertificados.FECHAS_INFORMES:
        return { ...state, fechas_informes: action.payload, loading: false }
    case 'LOGOUT':
        return DEFAULT_STATE
    default:
        return state
  }
}