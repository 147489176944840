import { actRetiros } from '../actions/retiros'

const DEFAULT_STATE = {
   lista: null,
   resumen: [{
      "id": "1",
      "nombre": "Vidrio",
      "kilogramos": null
   }, {
      "id": "2",
      "nombre": "Cartón",
      "kilogramos": null
   }, {
      "id": "3",
      "nombre": "Papel",
      "kilogramos": null
   }, {
      "id": "4",
      "nombre": "PET",
      "kilogramos": null
   }, {
      "id": "5",
      "nombre": "Latas",
      "kilogramos": null
   }, {
      "id": "6",
      "nombre": "EPS(Plumavit)",
      "kilogramos": null
   }, {
      "id": "7",
      "nombre": "Orgánicos",
      "kilogramos": null
   }
   ],
   retirosTotales: [],
   retiros13Meses: [],
   loading: false,
   loadingResumen: false,
   loadingResumen13Meses: false,
   error: null
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actRetiros.LOADING_RETIROS:
        return { ...state, loading: action.payload, error: null, loadingResumen: action.payload }
    case actRetiros.LOADING_RESUMEN:
        return { ...state, loadingResumen: action.payload, error: null }
    case actRetiros.ERROR_RETIROS:
        return { ...state, error: true, loading: false }
    case actRetiros.LISTA_RETIROS:
      let resumen = DEFAULT_STATE.resumen
      resumen.map(res => res.kilogramos = action.payload.filter(ret => ret.descripcion_categoria === res.nombre).map(r=>r.kilogramos_retirados).reduce(function (acc, val) { return acc + val; }, 0) )
      return { ...state, lista: action.payload,
         resumen: resumen,
         loading: false
      }
    case actRetiros.LISTA_RESUMEN:
        return { ...state,
          resumen: action.payload,
          loadingResumen: false }
   case actRetiros.SETEA_RETIROS_TOTALES:
      return { ...state,
         retirosTotales: action.payload,
         loadingResumen: false }
   case actRetiros.SETEA_RETIROS_13_MESES:
      return { ...state,
         retiros13Meses: action.payload,
         loadingResumen13Meses: false
      }
   case 'LOGOUT':
      return DEFAULT_STATE
    default:
        return state
  }
}


  // const isAuthenticated = state.loggedIn && Object.keys(state.user).length
  
