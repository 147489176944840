import axios from "axios";

const apiStrapi = axios.create({
    baseURL: process.env.STRAPI_URL,
    headers: {"Accept": "application/json", "Content-Type": "application/json"}
});
const apiGraphql = axios.create({
    baseURL: process.env.STRAPI_URL+"/graphql",
    method: 'post',
    headers: {"Accept": "application/json", "Content-Type": "application/json"}
});


const queryGraphql = query => new Promise(async (resolve, reject) => {
    const { data } = await apiGraphql.post("", 
    query
    /********** query example **********/
    //     {
    //     query: `mutation updateUserCity($id: Int!, $city: String!) {
    //       updateUserCity(userID: $id, city: $city){
    //         id
    //         name
    //         age
    //         city
    //         knowledge{
    //           language
    //           frameworks
    //         }
    //       }
    //     }`,
    //     variables: {
    //       id: 2,
    //       city: 'Test'
    //     }
    //   }
    )
    resolve(data)
}
)

export { apiStrapi, apiGraphql, queryGraphql };