import React from "react";
import moment from 'moment';
import 'moment/locale/es';

const textoAFecha = (fecha=new Date(), formato="YYYY-MM-DD") => {
    return moment(fecha, formato);
}

const fechaAtexto = (fecha=moment(), formato="YYYY-MM-DD") => {
    return fecha.format(formato);
}

const getMoment = () => {
    return moment;
}

const fechaActual = () => {
    return moment();
}

const FormatoFecha = ({fecha, tipo="xs", formato="DD/MM/YYYY" }) => {
    if(!fecha) return "";

    switch (tipo){
        case "xs":
            return(        
                <>{moment(fecha).format('L')}</>
            )
        case "sm":
            return(
                <>{moment(fecha).format('DD/MM/YYYY HH:mm')}</>
            )
        case "lg":
            return(
                <>{moment(fecha).format('LLLL')}</>
            )
        default:
            return(        
                <> {moment(fecha).format(formato)} </>
            )
    }
}
const InicioMesFecha = (fecha=moment(), formato="YYYY-MM-DD") => {
    if(!fecha) return "";
    const fmoment = moment(fecha);
    return fmoment.startOf('month').format(formato);
}
const FinMesFecha = (fecha=moment(), formato="YYYY-MM-DD") => {
    if(!fecha) return "";
    const fmoment = moment(fecha);
    return fmoment.endOf('month').format(formato);    
}
const FechaMesesAnteriores = (meses = 0, fecha=moment(), formato="YYYY-MM-DD") => {
    // if(meses>0) meses--
    const fmoment = moment(fecha);
    return fmoment.subtract(meses, 'months').format(formato);
}
export { FormatoFecha, textoAFecha, fechaAtexto, getMoment, fechaActual, InicioMesFecha, FinMesFecha, FechaMesesAnteriores };