import { queryGraphql, apiStrapi, apiGraphql } from '../../axios/axios'

import * as utils from '../../utils/utils'
import { setearEdificio } from './app'

const apiURL = process.env.STRAPI_URL

const actUseAuth = {
  LOGIN: 'LOGIN',
  MENU: 'MENU',
  FORGOT: 'FORGOT',
  RESET: 'RESET',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  UNSET_ERROR: 'UNSET_ERROR'
}

const login = (credentials) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actUseAuth.LOADING })
      const { data: payload } = await apiStrapi.post(`${apiURL}/auth/local`, credentials)
      console.debug(payload )

      // validación de Empresa y edificios para filtros de busqueda
      if(payload.user.edificios.length > 0 && payload.user.empresa){
        dispatch({ type: actUseAuth.LOGIN, payload })
        utils.setupAxios(apiGraphql, payload)
        dispatch(setearEdificio())
        // Setar el edificio seleccionado, desde el store o desde el localstorage en caso de haber seleccionado uno anteriormente
        const edificioTmp = utils.getStorage("edificio")
        if ( payload.user.edificios.length > 0 ){
          utils.setStorage("edificio", JSON.stringify(payload.user.edificios[0]))
          dispatch( setearEdificio(payload.user.edificios[0]) )
        } else if(edificioTmp !== "undefined"){
          console.debug(edificioTmp)
          dispatch( setearEdificio( JSON.parse(edificioTmp) ) )
        }
        return {status: true, msg: ""}
      }else{
        let setError = {status: false, msg: "El usuario no cuenta con Empresa y/o Edificio(s) asignados."}
        dispatch({ type: actUseAuth.ERROR, payload: setError })
        return setError
      }
    }catch(e){
      console.debug(e)
      let setError = {status: false}
      try{
        const { response: { data: { message: [{ messages: [error]}] } } } = e
        switch(error.id){
          case "Auth.form.error.email.provide":
            setError.msg = "Debe ingresar las credenciales de acceso."
            break
          case "Auth.form.error.password.provide":
            setError.msg = "Debe ingresar el password de acceso."
            break
          case "Auth.form.error.invalid":
            setError.msg = "Las credenciales de ingreso no son correctas."
            break
          default:
            setError.msg = "Problemas al iniciar sesión"
        }
        console.error(setError)
        dispatch({ type: actUseAuth.ERROR, payload: setError })
        return setError
      }catch(error){
        setError.msg = "Problemas para acceder al sistema de datos."
        console.error(setError)
        dispatch({ type: actUseAuth.ERROR, payload: setError })
        return setError
      }      
    }
  }
}
const forgot = (credentials) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actUseAuth.LOADING })
      const { data: payload } = await apiStrapi.post(`${apiURL}/auth/forgot-password`, credentials)
      dispatch({ type: actUseAuth.FORGOT, payload })
      return {status: true, msg: "Se ha enviado un email a la cuenta ingresada para restablecer el password"}
    }catch(e){
      let setError = {status: false}
      try{
        const { response: { data: { message: [{ messages: [error]}] } } } = e
        switch(error.id){
          case "Auth.form.error.email.provide":
            setError.msg = "Debe ingresar las credenciales de acceso."
            break
          case "Auth.form.error.user.not-exist":
            setError.msg = "El usuario/email ingresado no esta registado."
            break
          case "Auth.form.error.invalid":
            setError.msg = "Las credenciales de ingreso no son correctas."
            break
          default:
            setError.msg = "Problemas al recuperar el password."
        }
        dispatch({ type: actUseAuth.ERROR, payload: setError })
      }catch(e2){
        setError.msg = "Problemas al recuperar el password."
        dispatch({ type: actUseAuth.ERROR, payload: setError })
        return setError
      }
      console.error(setError)
      dispatch({ type: actUseAuth.ERROR, payload: setError })
      return setError
    }
  }
}
const reset = (credentials) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actUseAuth.LOADING })
      const { data: payload } = await apiStrapi.post(`${apiURL}/auth/reset-password`, credentials)
      dispatch({ type: actUseAuth.RESET, payload })
      return {status: true, msg: "Se ha seteado el nuevo password correctamente"}
    }catch(e){
      const { response: { data: { message: [{ messages: [error]}] } } } = e
      let setError = {status: false}
      switch(error.id){
        case "Auth.form.error.password.provide":
          setError.msg = "Debe ingresar el nuevo password."
          break
        case "Auth.form.error.passwordConfirmation.provide":
          setError.msg = "Debe repetir el nuevo password."
          break
        case "Auth.form.error.code.provide":
          setError.msg = "No es posible restablecer el password."
          break
        default:
          setError.msg = "Problemas al restablecer el password"
          dispatch({ type: actUseAuth.ERROR, payload: setError })
          return setError
      }
      console.error(setError)
      dispatch({ type: actUseAuth.ERROR, payload: setError })
      return setError
    }
  }
}

const menu = (id) => {
  return async (dispatch) => {
    try{
        
        // const { data: payload } = await apiGraphql.post("",
        const { data: payload } = await queryGraphql(
            // {data:
                {
                query: `query MyQuery($id: ID!) {
                    menuRol(id: $id) {
                    nombre
                    menus {
                        ruta
                        titulo
                    }
                    }
                }`,
                variables: {
                    id: id
                }
            }
        // }
        )
        console.debug(payload)
        dispatch({ type: actUseAuth.MENU, payload })
    }catch(e){
      console.log(e);
    }
  }
}
  
const logout = () => {
  return async (dispatch) => {
    console.log("se cierr la sesión")
    dispatch({ type: 'LOGOUT' })
  }
}

const unsetError = () => {  
  return async (dispatch) => {
    dispatch({ type: 'UNSET_ERROR' })
  }
}

export { actUseAuth, login, logout, forgot, reset, menu, unsetError }