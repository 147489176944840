import React from 'react'

import { createStore, compose, applyMiddleware } from 'redux'

import { persistStore, persistReducer } from "redux-persist"

import storage from 'redux-persist/lib/storage'

import ReduxThunk from 'redux-thunk'

import rootReducer from '../store/reducers'

const windowGlobal = typeof window !== 'undefined' && window

const devtools = process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
: f => f;

const persistConfig = {
    key: 'gestion_B',
    storage,
    blacklist: ['retiros', 'comentarios'] //reducers para no ser persistentes
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// exportar strore y persistor
export const store = createStore(persistedReducer,
    compose(applyMiddleware(ReduxThunk), devtools)
);
export const persistor = persistStore(store)