import { combineReducers } from 'redux';
import app from './app';
import auth from './useAuth'
import retiros from './retiros'
import comentarios from './comentarios'
import tickets from './tickets'
import ecovalencias from './ecovalencias'
import certificados from './certificados'

export default combineReducers({ auth: auth, app: app, retiros: retiros, comentarios: comentarios, tickets: tickets, ecovalencias: ecovalencias, certificados: certificados });
