import { queryGraphql } from '../../axios/axios'
import { fechaAtexto, textoAFecha } from '../../utils/momentUtils'

const actCertificados = {
  LISTA_CERTIFICADOS: 'LISTA_CERTIFICADOS',
  LOADING_CERTIFICADOS: 'LOADING_CERTIFICADOS',
  ERROR_CERTIFICADOS: 'ERROR_CERTIFICADOS',
  FECHAS_INFORMES: 'FECHAS_INFORMES'
}

const certificadosPeriodoEdificio = (edificio) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actCertificados.LOADING_CERTIFICADOS, payload: true })
        const { data: payload } = await queryGraphql(
            {
            query: `query qry($edificio: Int){
              certificados(where:{edificio:$edificio}){
                id
                fecha_emision
                documento{
                  name
                  url
                }
                tipo_certificado{
                  id
                  nombre
                }
              }
            }`,
            variables: {
              "edificio": edificio
            }
          }
        )
        console.debug(payload)
        dispatch({ type: actCertificados.LISTA_CERTIFICADOS, payload: payload.certificados })
    }catch(e){
      dispatch({ type: actCertificados.ERROR_CERTIFICADOS })
      console.log(e);
    }
  }
}

const informesPeriodoEdificio = (edificio) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actCertificados.LOADING_CERTIFICADOS, payload: true })
        const { data: payload } = await queryGraphql(
            {
            query: `query qry($edificio: Int){
              retiros(where:{edificio:$edificio}, sort: "fecha_retiro"){
                fecha_retiro
              }
            }`,
            variables: {
              "edificio": edificio
            }
          }
        )
        // console.debug(payload)
        let fechas = []
        payload.retiros.map(retiro => {
          const fecha = {fecha: fechaAtexto(textoAFecha(retiro.fecha_retiro), "MMYYYY") }
          if(fechas.findIndex(x => x.fecha==fechaAtexto( textoAFecha(retiro.fecha_retiro), "MMYYYY"))  === -1){
            fecha.id = fechas.length;
            fecha.month = fechaAtexto(textoAFecha(retiro.fecha_retiro), "MMMM")
            fecha.year = fechaAtexto(textoAFecha(retiro.fecha_retiro), "YYYY")
            fechas.push(fecha);}
        })
        // console.debug(fechas)
        dispatch({ type: actCertificados.FECHAS_INFORMES, payload: fechas })
    }catch(e){
      dispatch({ type: actCertificados.ERROR_CERTIFICADOS })
      console.log(e);
    }
  }
}

export { certificadosPeriodoEdificio, informesPeriodoEdificio, actCertificados }