import { queryGraphql } from '../../axios/axios'

const actTickets = {
  LISTA_TICKETS: 'LISTA_TICKETS',
  LOADING_TICKETS: 'LOADING_TICKETS',
  ERROR_TICKETS: 'ERROR_TICKETS'
}

const ticketsPeriodo = (edificio, periodoInicio, periodoFin) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actTickets.LOADING_TICKETS, payload: true })
        const { data: payload } = await queryGraphql(
            {
            query: `query qry($edificio: Int, $fechaIni: String, $fechaFin: String){
              tickets(where:{edificio:$edificio, fecha_entrega_gte: $fechaIni, fecha_entrega_lte: $fechaFin }, sort: "fecha_entrega"){
                id
                numero_ticket
                fecha_entrega
                kilogramos
                comprobante{
                  url
                  name
                }
              }
            }`,
            variables: {
              "edificio": edificio,
              "fechaIni": periodoInicio,
              "fechaFin": periodoFin
            }
          }
        )
        console.debug(payload)
        dispatch({ type: actTickets.LISTA_TICKETS, payload: payload.tickets })
    }catch(e){
      dispatch({ type: actTickets.ERROR_TICKETS })
      console.log(e);
    }
  }
}

export { ticketsPeriodo, actTickets }