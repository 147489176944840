import { actComentarios } from '../actions/comentarios'

const DEFAULT_STATE = {
  lista: null,
  loading: false,
  error: null
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actComentarios.LOADING_COMENTARIOS:
        return { ...state, loading: action.payload, error: null }
    case actComentarios.ERROR_COMENTARIOS:
        return { ...state, error: true, loading: false }
    case actComentarios.LISTA_COMENTARIOS:
        return { ...state, lista: action.payload, loading: false }
    default:
        return state
  }
}