import { queryGraphql, apiStrapi, apiGraphql } from '../../axios/axios'
import { actApp } from './app'
import { textoAFecha } from '../../utils/momentUtils'

const apiURL = process.env.STRAPI_URL

const actRetiros = {
  LISTA_RETIROS: 'LISTA_RETIROS',
  LISTA_RESUMEN: 'LISTA_RESUMEN',
  SETEA_RETIROS_TOTALES: 'SETEA_RETIROS_TOTALES',
  SETEA_RETIROS_13_MESES: 'SETEA_RETIROS_13_MESES',
  LOADING_RETIROS: 'LOADING_RETIROS',
  LOADING_RESUMEN: 'LOADING_RESUMEN',
  ERROR_RETIROS: 'ERROR_RETIROS',
  OK: 'OK'
}
/**
 * Texto fecha "YYYY-MM-DD", devuelve arreglo de los ultimos 13 meses anteriores a la fecha
 * @param {String} fecha
 */
const generaUltimos13Meses = (fecha) => {
  const fechaMoment = textoAFecha(fecha).add(1, 'months')
  return new Array(13).fill({periodo:null}).map( (mes, ind) => {
    return {periodo: fechaMoment.subtract(1, 'months').format('MMYYYY'), kilogramos: 0}
  })
}

const retirosPeriodoEdificio = (edificio, periodoInicio, periodoFin) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actRetiros.LOADING_RETIROS, payload: true })
        
        // const { data: payload } = await apiGraphql.post("",
        const { data: payload } = await queryGraphql(
            {
            // consulta para obtener retiros del periodo(detalle), suma total por categoría(para ecovalencias) y lista de categorías
            query: `query qry($edificio: Int, $fechaIni: String, $fechaFin: String){
              retiros(where:{edificio:$edificio, fecha_retiro_gte: $fechaIni, fecha_retiro_lte: $fechaFin }, sort: "fecha_retiro"){
                id
                fecha_retiro
                kilogramos_retirados
                numero_guia
                descripcion
                categoria {
                  nombre
                  descripcion
                }
              }              
              retirosConnection(where:{ edificio:$edificio, fecha_retiro_gte: $fechaIni, fecha_retiro_lte: $fechaFin }) {
                groupBy {
                  categoria {
                    connection {
                      aggregate {
                        sum {
                          kilogramos_retirados
                        }
                      }
                    }
                    key          
                  }
                }
              }
              categorias{
                id
                nombre
              }
            }`,
            variables: {
              "edificio": edificio,
              "fechaIni": periodoInicio,
              "fechaFin": periodoFin
            }
          }
        )
        // console.debug(payload)
        payload.retiros.map(retiro => {retiro.nombre_categoria = retiro.categoria.nombre;retiro.descripcion_categoria = retiro.categoria.descripcion;} )
        console.debug(payload)
        
        dispatch({ type: actRetiros.LISTA_RETIROS, payload: payload.retiros })
        
        const datos = payload.retirosConnection.groupBy.categoria
        const categorias = payload.categorias
        console.debug(datos)
        console.debug(categorias)
        categorias.map(cat => (cat.kilogramos = (datos.filter(suma => cat.id === suma.key)[0])?datos.filter(suma => cat.id === suma.key)[0].connection.aggregate.sum.kilogramos_retirados:null) )
        dispatch({ type: actRetiros.SETEA_RETIROS_TOTALES, payload: categorias })
        
        const ultimos13Meses = generaUltimos13Meses(periodoInicio)
        
        const { data: retirosGrafica } = await queryGraphql(
          {
          // consulta para obtener la fecha de retiros mas antigua(límite), y kilogramos retirados por fecha(ambas para grafica de 13 meses)
          query: `query qry($edificio: Int, $fechaIni: String, $fechaFin: String){
            retiros(where:{ edificio:$edificio },sort: "fecha_retiro", limit: 1) {
              fecha_retiro
            }
            primer_retiro:retiros(where:{ edificio:$edificio }, sort: "fecha_retiro:asc", limit: 1) {
              fecha_retiro
            }
            ultimo_retiro:retiros(where:{ edificio:$edificio }, sort: "fecha_retiro:desc", limit: 1) {
              fecha_retiro
            }
            retirosConnection(where:{ edificio:$edificio, fecha_retiro_gte: $fechaIni, fecha_retiro_lte: $fechaFin }) {
              values {
                fecha_retiro
                kilogramos_retirados
              }
            }
          }`,
          variables: {
            "edificio": edificio,
            "fechaIni": textoAFecha(periodoInicio).subtract(13, 'months').format('YYYY-MM-DD'),
            "fechaFin": periodoFin
          }
        }
      )
      dispatch({ type: actApp.SET_ULTIMO_RETIRO, payload: (retirosGrafica.ultimo_retiro.length > 0)?retirosGrafica.ultimo_retiro[0].fecha_retiro:null })

      const fechaLimiteInicio = (retirosGrafica.primer_retiro.length > 0)?retirosGrafica.primer_retiro[0].fecha_retiro:textoAFecha()
      ultimos13Meses.forEach( meses =>
        {
          retirosGrafica.retirosConnection.values.map( retiros =>
            {if(meses.periodo ===  textoAFecha(retiros.fecha_retiro).format('MMYYYY') ){
              meses.kilogramos += retiros.kilogramos_retirados
            }
            }
          )
          // console.debug( fechaLimiteInicio, textoAFecha(meses.periodo, "MMYYYY"), textoAFecha(fechaLimiteInicio).startOf('month'), textoAFecha(meses.periodo, "MMYYYY").isSameOrBefore( textoAFecha(fechaLimiteInicio).startOf('month') ) )
          meses.visible = ( textoAFecha(meses.periodo, "MMYYYY").isSameOrAfter( textoAFecha(fechaLimiteInicio).startOf('month') ) )
        }
      )
      console.debug(ultimos13Meses)

      dispatch({ type: actRetiros.SETEA_RETIROS_13_MESES, payload: ultimos13Meses })
      // dispatch({ type: actRetiros.LOADING_RETIROS, payload: false })
    }catch(e){
      dispatch({ type: actRetiros.ERROR_RETIROS })
      console.log(e);
    }
  }
}

// ACCION NO UTILIZADA, el resumen actualmente se realiza a partir del resultado de retiro completo
const retirosPeriodoResumen = (edificio, periodoInicio, periodoFin, categorias) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actRetiros.LOADING_RESUMEN, payload: true })
        
        // const { data: payload } = await apiGraphql.post("",
        const { data: payload } = await queryGraphql(
            {
            query: `query qryresumen($edificio: Int, $fechaIni: String, $fechaFin: String){
              retirosConnection(where:{edificio:$edificio, fecha_retiro_gte: $fechaIni, fecha_retiro_lte: $fechaFin }) {
                  groupBy {
                    categoria {
                      connection {
                        aggregate {
                          sum {
                            kilogramos_retirados
                          }
                        }
                      }
                      key          
                    }
                  }
                }
            }`,
            variables: {
              "edificio": edificio,
              "fechaIni": periodoInicio,
              "fechaFin": periodoFin
            }
          }
        )
        const datos = payload.retirosConnection.groupBy.categoria
        console.debug(datos)
        console.debug(categorias)
        categorias.map(cat => (cat.kilogramos = (datos.filter(suma => cat.id === suma.key)[0])?datos.filter(suma => cat.id === suma.key)[0].connection.aggregate.sum.kilogramos_retirados:null) )
        console.debug(categorias)

        dispatch({ type: actRetiros.LISTA_RESUMEN, payload: categorias })
    }catch(e){
      dispatch({ type: actRetiros.ERROR_RETIROS })
      console.log(e);
    }
  }
}

// ACCION NO UTILIZADA, el resumen actualmente se realiza a partir del resultado de retiro completo
const retirosResumen = (edificio) => {
  return async (dispatch) => {
    try{
      dispatch({ type: actRetiros.LOADING_RESUMEN, payload: true })
        
        // const { data: payload } = await apiGraphql.post("",
        const { data: payload } = await queryGraphql(
            {
            query: `query qryresumen( $edificio: Int ){
              retirosConnection(where:{ edificio:$edificio }) {
                groupBy {
                  categoria {
                    connection {
                      aggregate {
                        sum {
                          kilogramos_retirados
                        }
                      }
                    }
                    key          
                  }
                }
              }
              categorias{
                id
                nombre
              }
            }`,
            variables: {
              "edificio": edificio
            }
          }
        )
        const datos = payload.retirosConnection.groupBy.categoria
        const categorias = payload.categorias
        console.debug(datos)
        console.debug(categorias)
        categorias.map(cat => (cat.kilogramos = (datos.filter(suma => cat.id === suma.key)[0])?datos.filter(suma => cat.id === suma.key)[0].connection.aggregate.sum.kilogramos_retirados:null) )
        console.debug(categorias)

        dispatch({ type: actRetiros.LISTA_RESUMEN, payload: categorias })
        return {categorias}
        // dispatch({ type: actRetiros.LOADING_RESUMEN, payload: false })
    }catch(e){
      dispatch({ type: actRetiros.ERROR_RETIROS })
      console.log(e);
    }
  }
}

export { retirosPeriodoEdificio, retirosPeriodoResumen, generaUltimos13Meses, actRetiros }