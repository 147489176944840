import { actEcovalencias } from '../actions/ecovalencias'

const DEFAULT_STATE = {
  lista: null,
  loading: false,
  error: null,
  ecovalencias: [
      {nombre: "Árboles", suma: 0},
      {nombre: "Duchas", suma: 0},
      {nombre: "Contenedores", suma: 0},
      {nombre: "Metros cúbicos", suma: 0},
      {nombre: "Energía", suma: 0},
      {nombre: "Combustible", suma: 0},
      {nombre: "CO2", suma: 0},
      {nombre: "Agua", suma: 0},
      {nombre: "Viajes", suma: 0},
      {nombre: "Hogares", suma: 0}
  ]
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actEcovalencias.LOADING_ECOVALENCIAS:
        return { ...state, loading: action.payload, error: null }
    case actEcovalencias.ERROR_ECOVALENCIAS:
        return { ...state, error: true, loading: false }
    case actEcovalencias.LIMPIAR_SUMA:
        return { ...state, ecovalencias: DEFAULT_STATE.ecovalencias }
    case actEcovalencias.CALC_ECOVALENCIAS:
        console.log(DEFAULT_STATE)
        state.ecovalencias = DEFAULT_STATE.ecovalencias
        state.ecovalencias.map(eco => {
            // console.log(eco.nombre)
            eco.suma = 0
            action.payload.map(retiro =>{
                state.lista.map(ecovalencia => {
                    if( eco.nombre === ecovalencia.de && retiro.nombre === ecovalencia.un_de ){
                        eco.suma += retiro.kilogramos * ecovalencia.equivale_a
                        console.debug("Si:", retiro.nombre, ":", retiro.kilogramos, ", equivale a: ", ecovalencia.equivale_a, ecovalencia.de, retiro.kilogramos * ecovalencia.equivale_a)
                    }
                    // console.debug(eco.nombre, retiro.nombre, ecovalencia.un_de, ecovalencia.de)
                })
            })
        })
        console.log(state.ecovalencias)
        return { ...state }
    case actEcovalencias.LISTA_ECOVALENCIAS:
        return { ...state, lista: action.payload }
    case 'LOGOUT':
        return DEFAULT_STATE
    default:
        return state
  }
}