import { actTickets } from '../actions/tickets'

const DEFAULT_STATE = {
  lista: null,
  loading: false,
  error: null
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type){
    case actTickets.LOADING_TICKETS:
        return { ...state, loading: action.payload, error: null }
    case actTickets.ERROR_TICKETS:
        return { ...state, error: true, loading: false }
    case actTickets.LISTA_TICKETS:
        return { ...state, lista: action.payload, loading: false }
    default:
        return state
  }
}