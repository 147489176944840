import React from 'react'

import { Provider } from 'react-redux'

import { PersistGate } from "redux-persist/integration/react"

import { store, persistor } from '../store'

const wrapRootElement = ( {element} ) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading="Cargando">
        {element}
      </PersistGate>
    </Provider>
  )
}

export { wrapRootElement }